export const videoArray = [{
    id: 'myPlayerPuman',
    name: '普满乡烟叶种植基地',
    county: '嘉禾县',
    countyCode: '431024',
    lat: 25.697045,
    lon: 112.528542,
    icon: '/static/monitor/sub_shexiangtou.png',
    url: 'http://cmgw-vpc.lechange.com:8888/LCO/7E03262PANB545F/0/0/20210719T012824/559af31a1b0d8498e88dc1eb4418bed0.m3u8',
    nearStationId: 'P8594',
}, {
    id: 'myPlayerGuangfa',
    name: '广发乡烟叶种植基地',
    city: '郴州市',
    county: '嘉禾县',
    countyCode: '431024',
    lat: 25.68307,
    lon: 112.301686,
    icon: '/static/monitor/sub_shexiangtou.png',
    url: 'http://cmgw-vpc.lechange.com:8888/LCO/7E03262PAN93333/0/0/20210720T143603/140ff349751e1328cea6a24351a4737a.m3u8',
    nearStationId: 'P8673',
}]